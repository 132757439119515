import React, { useContext, useState } from "react";
import { GlobalContext } from "../contexts/GlobalContext.jsx";
import { getExportActionPlansURL } from "../../services/task-tracking";
import IconExportXLSX from '../../images/export-xlsx.ico';
import { Tooltip } from 'antd';
import ReportUsageTracker from "./ReportUsageTracker.jsx";

export default function ExportActionPlans() {
  const { session } = useContext(GlobalContext);
  const [trackUsage, setTrackUsage] = useState(false)

  return (
    <div className="hide-on-print">
      <Tooltip title="Exportar planes de acción">
        <a href={getExportActionPlansURL(session.accessToken, session.qsArray)} onClick={() => setTrackUsage(true)}>
          <img
            className="m-2 is-clickable"
            src={IconExportXLSX}
            alt="Exportar planes de acción"
            height="30"
            width="30"
          />
        </a>
      </Tooltip>

      {trackUsage && (
        <ReportUsageTracker userToken={session.userToken} customerCode={session.customerCode} reportID={64}/>
      )}
    </div>
  );
};
