import { number, string } from 'prop-types';
import { useEffect } from 'react';
import { sendReportUse } from '../../services/asysgo';

export default function ReportUsageTracker({ userToken, reportID, customerID, customerCode }) {
  const origin = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent) ? 'mobile' : 'desktop';

  useEffect(() => {
    const hasValidCustomer = customerID || customerCode;
    if (!userToken || !reportID || !hasValidCustomer) {
      return;
    }

    const registerReportUsage = async () => {
      try {
        await sendReportUse(userToken, customerID, customerCode, reportID, origin);
      } catch (error) {
        window.console.error('Failed to register report usage:', error);
      }
    };

    registerReportUsage();
    // eslint-disable-next-line
  }, [userToken, customerID, customerCode, reportID]);

  return null;
}

ReportUsageTracker.propTypes = {
  userToken: string.isRequired,
  reportID: number.isRequired,
  customerID: number,
  customerCode: string,
};

ReportUsageTracker.defaultProps = {
  customerID: null,
  customerCode: null,
};
